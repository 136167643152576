
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import {filters} from "@/components/statistics/misc/types";

export default defineComponent({
    name: "jobs-by-worker-pie-chart",
    components: {
      LoadingSpinner
    },
    props: {
      filters: {
        type: Object as PropType<filters>,
        default: {} as filters
      }
    },
    setup(props) {
      const store = useStore();
      const key = ref<number>(0);
      const isLoading = ref<boolean>(false);
      const isEmpty = ref<boolean>(false);
      const data = computed(
          () => store.getters["StatisticsModule/jobsByWorker"]
      );

      const chart = reactive({
        options: {
          labels: [],
          series: [],
          noData: {
            text: "Loading..."
          },
          chart: {
            width: 380,
            type: 'pie',
          }
        }
      });
      const setData = () => {
        if(data.value.values.length == 0) {
          isEmpty.value = true;
          return;
        } else {
          isEmpty.value = false;
        }
        chart.options.labels = data.value.labels;
        chart.options.series = data.value.values;
        key.value++;
      };
      function buildQueryString():string {
        let queryString = "";
        Object.keys(props.filters).forEach(key => {
          if(props.filters[key] != null && props.filters[key] != "") {
            queryString += `&${key}=${props.filters[key]}`
          }
        })
        return queryString;
      }
      const setLoading = (value) => {
        isLoading.value = value;
      }
      onMounted(() => {
        const query = buildQueryString();
        setLoading(true);
        store.dispatch("StatisticsModule/fetchJobsByWorker", query).then(() => {
          setData();
          setLoading(false);
        })
      });

      watch(props.filters, () => {
        const query = buildQueryString();
        setLoading(true);
        store.dispatch("StatisticsModule/fetchJobsByWorker", query).then(() => {
          setData();
          setLoading(false);
        })
      }, {deep:true});

      return {
        isLoading,
        isEmpty,
        chart,
        key
      }
    }
});
