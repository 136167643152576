<template>
  <el-select
    v-model="value"
    size="large"
    clearable
    filterable
    placeholder="Select a client"
    class="w-100"
  >
    <el-option
      v-for="item in companies"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "ClientDropdown",
  data() {
    return {
      value: ""
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchCompaniesAll"])
  },
  mounted() {
    this.fetchCompaniesAll().then(() => {
      if (this.$route.query.company_id) {
        this.value = this.companies.find(
          i => i.value == this.$route.query.company_id
        ).label;
      }
    });
  },
  computed: {
    ...mapGetters("ListsModule", ["companies"])
  }
});
</script>
