
import {defineComponent, ref, reactive} from "vue";
import {useStore} from "vuex";
import ClientDropdown from "@/components/dropdown/ClientDropdown.vue";
import BranchesDropdown from "@/components/dropdown/BranchesDropdown.vue";
import JobsByWorkerPieChart from "@/components/statistics/charts/JobsByWorkerPieChart.vue";
import ReportedHoursByWorker from "@/components/statistics/charts/ReportedHoursByWorker.vue";
import {filters} from "@/components/statistics/misc/types";

export default defineComponent({
  name: "statistics-workers",
  components: {
    ClientDropdown,
    BranchesDropdown,
    JobsByWorkerPieChart,
    ReportedHoursByWorker
  },
  setup() {
    const store = useStore();
    const filter = reactive({
      client: "",
      branch: "",
      date: null
    })  as filters;
    const dateArray = ref([]);
    return {
      filter,
      dateArray
    }
  }
});
